import React, { useLayoutEffect, useRef, useState } from "react"
import { graphql } from "gatsby"
import SEO from "../components/seo"
import { motion, useTransform, useViewportScroll } from "framer-motion"
import Layout from "../components/layout"

const PrivacyPage = ({data}) => {
  const [elementTop, setElementTop] = useState(0)
  const elTitle = useRef()
  const page = data.markdownRemark
  const { scrollY } = useViewportScroll()
  const y = useTransform(scrollY, [elementTop, elementTop + 1], [0, -1], {
    clamp: false,
  })

  useLayoutEffect(() => {
    const element = elTitle.current
    setElementTop(element.offsetTop)
  }, [elTitle])

  return (
    <Layout ctx={"privacy"}>
      <SEO title={page.frontmatter.title} />
      <div className="page-jumbotron" ref={elTitle}>
        <motion.h1 style={{ y }}>{page.frontmatter.title}</motion.h1>
      </div>
      <div
        className={"page-content"}
        dangerouslySetInnerHTML={{ __html: page.html }}
      />
    </Layout>
  )
}

export default PrivacyPage

export const query = graphql`
  query Privacy {
    markdownRemark(fileAbsolutePath: { regex: "/privacy.md/" }) {
      html
      frontmatter {
        title
      }
    }
  }
`
